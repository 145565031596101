<template>
  <div>
    <div class="flix-navbar flix-navbar-small">
      <div class="flix-container" style="margin-bottom: 30px;">
        <div class="flix-grid">
          <div class="flix-grid-item" v-for="part, partIndex in getItems" :key="partIndex">
            <h3 class="flix-html-h3"><flixIcon :id="part.icon" /> {{ part.name }}</h3>
            <hr />
            <ul class="flix-list-unstyled flix-clear-margins">
              <li v-for="list, listIndex in part.items" :key="listIndex">
                <router-link :to="list.link" class="flix-html-a" v-if="list.permission()">
                  <flixIcon :id="list.icon" /> {{ list.name }}
                </router-link>
                <span v-else class="flix-disabled">
                  <flixIcon :id="list.icon" /> {{ list.name }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import menu from './index.vue'
export default {
  extends: menu,
  components: {},
  props: {},
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>

  .flix-navbar
    background-color: inherit
    border-color: inherit
    border: 0
    font-size: 12pt
    .flix-grid
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-auto-rows: 1fr
      grid-column-gap: 10px
      grid-row-gap: 10px
      @media (max-width: 765px)
        grid-row-gap: 0px
        grid-template-columns: repeat(1, 1fr)
        margin-top: 0px
        gap: 20px
        grid-auto-rows: 0fr
      li
        line-height: 1.2em
        margin-bottom: 23px

    .router-link-exact-active
      font-weight: bold
</style>
